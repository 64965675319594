<template>
  <div class="notFound">
    <div class="top">
      <img :src="logoImg" alt="">
    </div>
    <div class="content">
      <img :src="notFoundImg" alt="">
      <p>抱歉您访问的页面不存在， 点击按钮返回首页</p>
      <div class="btn">
        <el-button type="primary" @click="$router.push('/home')">首页</el-button>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Footer from '@/components/Footer.vue'
  import conf from "@/utils/conf";
  export default {
    data() {
      return {
        logoImg:conf.getLogoImg(),
        notFoundImg:conf.getNotFoundImg()
      }
    },
    methods: {},
    components: {
      Footer
    }
  }
</script>

<style lang="less" scoped>
  .notFound {
    height: 100vh;

    .top {
      width: 100%;
      height: 49px;
      display: flex;
      align-items: center;
      padding-left: 300px;
      background-color: #fff;
    }

    .content {
      display: flex;
      padding: 10px 0;
      align-items: center;
      flex-direction: column;
      background-color: #fff;
      margin: 30px auto;
      width: 60%;
      /*margin-bottom: 50px;*/

      .btn{
        margin-top: 500px;
      }

      p {
        color: #797979;
        margin: 10px 0;
      }

    }
  }

</style>
