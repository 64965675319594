/**
* @Description 龙芯应用公社
* @author Loongson.Quzhenhong
* @date 21-7-6
*/
<template>
  <div class="footer" ref="footer">
    <div class="ft-content">
      <div class="left">
        <img class="logo" :src="bigLogoImg" alt="">
        <div class="line"></div>
        <div class="ma">
          <img class="ma" :src="shareImg" alt="">
        </div>
        <div class="item">
          <p>平台运营：龙芯中科（山西）技术有限公司</p>
          <p>客服邮箱:{{getContactEmail()}}  公司地址: 山西省长治市龙芯信创产业园</p>
        </div>
      </div>
    </div>
    <div class="copy_right">
      <span class="cr_time">©{{new Date().getFullYear()}}</span>
      <span class="cr_company">龙芯中科(山西)技术有限公司&nbsp</span>
      <span class="cr_verticle_line">|</span>
      <a href="https://beian.miit.gov.cn/" class="cr_icp">&nbsp晋ICP备2023015439号-1&nbsp&nbsp&nbsp</a>
      <img src="../assets/images/national.png" alt="">
      <a href="https://beian.mps.gov.cn/#/query/webSearch">晋公网安备14040302000131号</a>
    </div>
<!--    <a class="footerDiv" href="https://beian.miit.gov.cn/">©{{new Date().getFullYear()}} 龙芯中科(山西)技术有限公司 | 晋ICP备2023015439号-1</a>-->
  </div>
</template>

<script>
  import emailUtils from "@/utils/emailUtils";
  import conf from "@/utils/conf";
  export default {
    name: 'Footer',
    data() {
      return {
        navs: [
          {
            path: '/home',
            name: '首页'
          }, {
            path: '/hot',
            name: '热门软件推荐'
          },
          {
            path: '/cate',
            name: '软件分类'
          }, {
            path: '/install',
            name: '装机必备'
          },
          {
            path: '/speak',
            name: '热门帖子'
          },
          {
            path: '/new',
            name: '最新上架'
          },
          {
            path: '/email',
            name: '联系我们'
          }
        ],
        defaultImg: require('@/assets/images/national.png'),
        footerUrl: conf.getFooterImg(),
        bigLogoImg:conf.getBigLogoImg(),
        shareImg:conf.getShareImg(),
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.footer.style.backgroundImage = `url(${this.footerUrl})`
      })
    },
    methods: {
      goNav(value) {
        this.$router.push(value)
      },
      getContactEmail() {
        return emailUtils.getEmail();
      }
    },
    components: {}
  }
</script>

<style lang="less" scoped>

  .footer {
    // background: url("../images/footer.jpg") no-repeat;
    background-size: cover;
    width: 100%;
    height: 120px;
    display: flex;

    justify-content: space-around;
    align-items: center;

    .ft-content{
      margin-top: -10px;
      .left {
        display: flex;
        margin-left: 200px;

        .logo {
          height: 40px;
          margin-top:15px;
        }
        .line {
          height: 40px;
          width: 1px;
          margin: 15px 50px;
          background-color: #fff;
        }
        .ma {
          margin-top: 6px;
          height: 50px;
          //background-color: #fff;
        }
        .item{
          width: 550px;
          margin-left: 200px;
          margin-top: 10px;
          cursor: pointer;
          overflow: auto;
          text-align: center ;
          vertical-align: middle;
          p{
            font-size: 16px;
            padding: 6px;
            color: white;
          }
        }
      }
      .contact {
        color: #fff;
        display: flex;
        margin-top: 0px;
        margin-left: 800px;
        width: 550px;
        overflow: auto;
        text-align: center ;
        vertical-align: middle;
        justify-content: space-between;
      }
    }

    .copy_right{
      height: 20px;
      font-size: 12px;
      margin-top:80px;
      align-items: center;
      color: #ffd6d6;
      display: flex;
      position: absolute;
      text-align: center ;
      min-width: 500px;
      span{
        line-height: 20px;
      }
      a{
        color: #ffd6d6;
      }
      img{
        display: inline-block;
        width:14px;
        height:14px;
        margin-top: -3px;
      }
    }
  }
</style>
